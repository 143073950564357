/**
 * @method normalizeToCamelcase
 * @param {string} string to convert into camelcase.
 * @description Normalizes a string into a camelcase.
 * @return Camelcase string.
 */
export const normalizeToCamelcase = (string) => {
  const regex = /\s+(\w)?/gi;

  if (!string) {
    return '';
  }

  return string
    .toLowerCase()
    .replace(regex, (match, text) => text.toUpperCase());
};
