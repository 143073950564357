// Package dependencies
import { AddressSubSchema, SuperSchema } from '@updater/ui-informant';

// Local dependencies
import { PHONE_NUMBER_REGX } from 'constants/regx';
import { normalizeToCamelcase } from 'utils/normalizeToCamelcase';
import { BASE_SURE_PATH } from '../constants';
import {
  customAllOf,
  customDataSchema,
  customRequired,
  layoutScreensRef,
  newScreens,
  screens,
  interestedPartyAddressSchema,
  mailingAddressSchema,
} from './transform-to-schema-config';
import { FieldDataT, LayoutDataT, UiDataT } from './transform-to-schema-types';

/**
 * @method setNewScreen
 * @description Sets a new full screen (layout, schema) that is not part of the API response screens.
 * @param {Object} dataSchema The full dataSchema configuration.
 * @param {Object} layoutSchema The all layouts schema configuration.
 */
const setNewScreen = (dataSchema, layoutSchema) => {
  const actualDataSchema = dataSchema;

  newScreens.forEach((screen) => {
    const { keyName, layout, schema } = screen;

    actualDataSchema.properties[keyName] = schema;
    layoutSchema.push(layout);
  });
};

/**
 * @method getLayoutData
 * @description Gets the actual screen data with all the properties and configurations.
 * @param {Object} actualScreenData The actual screen data configuration that comes from dynamicForm.screens.
 * @returns {Object}
 */
const getActualLayoutScreen = (actualScreenData) => {
  return Object.values(layoutScreensRef).find((layout) => {
    return layout?.question?.find((question) => {
      return question === actualScreenData.question;
    });
  });
};

/**
 * @method setDataSchema
 * @description Sets the schema properties configuration.
 * @param {Object} dataSchema The dataSchema model.
 * @param {Object} data The type of data that the fields manage.
 * @param {Object} ui The UI config (label, id, values, component) that is shown on the fields.
 * @param {Object} field The fields that the screen has.
 */
export const setDataSchema = (dataSchema, data, ui, field) => {
  const actualDataSchema = dataSchema;
  const actualSchemaRequired = dataSchema.required;

  actualSchemaRequired.push(...customRequired, field.key_name);
  actualDataSchema.properties[field.key_name] = {
    ...data,
    ui,
  };

  actualDataSchema.required = Array.from(new Set(actualSchemaRequired));
};

/**
 * @method setCustomDataSchema
 * @description Sets the custom data schema configuration adding to the data schema config that comes from the API.
 * @param {Object} dataSchema The dataSchema model.
 */
const setCustomDataSchema = (dataSchema) => {
  const actualDataSchema = dataSchema;

  Object.keys(customDataSchema).forEach((key) => {
    const currentCustomSchema = {};

    currentCustomSchema[key] = customDataSchema[key];
    actualDataSchema.properties = {
      ...dataSchema.properties,
      ...currentCustomSchema,
    };
  });
};

/**
 * @method setCustomAllOf
 * @description Sets the custom oneAll scenario to set a new behavior when is needed.
 * @param {Object} dataSchema The dataSchema model.
 */
const setCustomAllOf = (dataSchema) => {
  customAllOf.forEach((elem) => {
    dataSchema.allOf.push(elem);
  });
};

/**
 * @method setDataSchemaForAddressScreen
 * @description Sets all the schema configuration for an Address screen.
 * @param {Object} dataSchema The dataSchema model.
 * @param {string} addressPropertyName The addressFieldName property for the screen.
 * @param {Array<string>} keyNames  The field's key.
 */
const setDataSchemaForAddressScreen = (
  dataSchema: { [key: string]: any },
  addressPropertyName,
  keyNames
) => {
  const actualDataSchema = dataSchema;

  if (addressPropertyName === 'intrested_party_address') {
    // for the interested party's address we don't need the functionality of the
    // address component so we just use a simple schema which has a bunch of
    // string inputs
    actualDataSchema.properties = {
      ...actualDataSchema.properties,
      ...interestedPartyAddressSchema,
    };
  } else if (addressPropertyName === 'mailing_address') {
    // for the mailing_address address we don't need the functionality of the
    // address component so we just use a simple schema which has a bunch of
    // string inputs
    actualDataSchema.properties = {
      ...actualDataSchema.properties,
      ...mailingAddressSchema,
    };
  } else {
    actualDataSchema.properties[addressPropertyName] = {
      ...AddressSubSchema,
    };
  }

  keyNames.push(addressPropertyName);
};

/**
 * @method setDataConditionalSchemaForAddressScreen
 * @description Sets all the schema conditionals configuration for an Address screen.
 * @param {Object} dataSchema The dataSchema model.
 * @param {string} addressFieldName The addressFieldName property for the screen.
 * @param {Object} conditionalScreen Data to validate if theres is conditional screen type.
 */
const setDataConditionalSchemaForAddressScreen = (
  dataSchema: { [key: string]: any },
  addressFieldName,
  conditionalScreen
) => {
  if (conditionalScreen) {
    dataSchema.allOf.push({
      if: {
        properties: conditionalScreen,
      },
      then: {
        properties: {
          [addressFieldName]: {
            type: 'object',
            ui: {
              component: 'address',
            },
          },
        },
        required: [addressFieldName],
      },
    });
  }
};

/**
 * @method setDataSchemaForConditionalsScreen
 * @description Sets all the schema configuration for conditionals screen.
 * @param {Object} dataSchema The dataSchema model.
 * @param {Object} field The fields that the screen has.
 * @param {Object} data The type of data that the fields manage.
 * @param {Object} ui The UI config (label, id, values, component) that is shown on the fields.
 * @param {boolean} conditionalScreen Validator for a conditional screen type.
 */
const setDataSchemaForConditionalsScreen = (
  dataSchema,
  field,
  data,
  ui,
  conditionalScreen
) => {
  dataSchema.allOf.push({
    if: {
      properties: conditionalScreen,
    },
    then: {
      properties: {
        [field.key_name]: {
          ...data,
          ui,
        },
      },
      required: [field.key_name],
    },
  });
};

/**
 * @method setDataSchemaForExtraScreens
 * @description Sets all the schema configuration for extra screens.
 * @param {Array<Object>} extraScreens The dataSchema list for extraScreens.
 * @param {Object} dynamicForm The Sure flow config that comes form the API response.
 * @param {Object} dataSchema The dataSchema model.
 * @param {Object} outputScreens The screenReader method return.
 */
const setDataSchemaForExtraScreens = (
  extraScreens,
  dynamicForm,
  dataSchema,
  outputScreen
) => {
  extraScreens.forEach((screenField) => {
    const dynamicFormKeys = Object.keys(dynamicForm.screens);
    const nextScreenChoices = screenField.settings?.choices?.filter(
      (choice) => choice.screen_id
    );

    nextScreenChoices.forEach((choice) => {
      const nextScreenIndex = choice.screen_id;
      const currentScreenData = dynamicForm.screens[nextScreenIndex];
      const conditionalScreen = {
        [screenField.key_name]: {
          const: choice.value.toString(),
        },
      };

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return screenReading({
        dynamicForm,
        dynamicFormKeys,
        screenData: currentScreenData,
        screenIndex: nextScreenIndex,
        outputScreen,
        dataSchema,
        conditionalScreen,
      });
    });
  });
};

/**
 * @method setFieldForRadioListBooleanType
 * @description Sets the schema fields config for radiolist boolean type.
 * @param {Object} field The fields that the screen has.
 * @param {Object} data The type of data that the fields manage.
 * @param {Object} ui The UI config (label, id, values, component) that is shown on the fields.
 */
const setFieldForRadioListBooleanType = (field, data, ui) => {
  const choices = field.settings.choices.reverse();
  const actualData = data;
  const actualUi = ui;

  actualData.oneOf = choices.map((choice) => ({
    const: choice.value.toString(),
    title: choice.title,
  }));
  actualData.default = choices[0].value.toString();
  actualUi.component = 'radiolist';
};

/**
 * @method setFieldRadioListTextType
 * @description Sets the schema fields config for radiolist text type.
 * @param {Object} field The fields that the screen has.
 * @param {Object} data The type of data that the fields manage.
 * @param {Object} ui The UI config (label, id, values, component) that is shown on the fields.
 */
const setFieldRadioListTextType = (field, data, ui) => {
  const actualData = data;
  const actualUi = ui;

  actualData.oneOf = field.settings.choices.map((choice) => ({
    const: choice.value,
    title: choice.title,
  }));
  actualData.default = field.settings.choices[0].value;
  actualUi.component = 'radiolist';
};

/**
 * @method setFieldSelectType
 * @description Sets the schema fields config for select list type.
 * @param {Object} field The fields that the screen has.
 * @param {Object} data The type of data that the fields manage.
 * @param {Object} ui The UI config (label, id, values, component) that is shown on the fields.
 */
const setFieldSelectType = (field, data, ui) => {
  const actualData = data;
  const actualUi = ui;

  actualData.oneOf = field.settings.choices.map((choice) => ({
    const: choice.value,
    title: choice.title,
  }));
  actualUi.component = 'select';
  actualUi.options = field.settings.choices.map((choice) => ({
    id: choice.value,
    label: choice.title,
    value: choice.value,
  }));
};

/**
 * @method setFieldDatePickerType
 * @description Sets the schema fields config for date picker type.
 * @param {Object} ui The UI config (label, id, values, component) that is shown on the fields.
 */
const setFieldDatePickerType = (ui) => {
  const actualUi = ui;

  actualUi.component = 'datePicker';
  actualUi.label = 'Claim date';
  actualUi.messages = {
    ...actualUi.messages,
    required: 'Date of your most recent claim is required',
  };
  actualUi.componentProps = {
    minDate: null,
    maxDate: new Date(),
  };
};

/**
 * @method setFieldPhoneNumberType
 * @description Sets the schema fields config for input type phone number.
 * @param {Object} data The type of data that the fields manage.
 * @param {Object} ui The UI config (label, id, values, component) that is shown on the fields.
 */
const setFieldPhoneNumberType = (data, ui) => {
  const actualData = data;
  const actualUi = ui;

  actualData.pattern = PHONE_NUMBER_REGX;
  actualUi.component = 'phone';
  actualUi.messages = {
    required: 'Phone number is required!',
    pattern: 'Please enter a valid value',
  };
};

/**
 * @method setLayoutSchema
 * @description Sets the schema fields config that comes from the API but overwrites with the
 * config that comes layoutScreensRef, in case that a custom field is needed.
 * @param {Object} layoutData The current layout schema configuration.
 * @param {Object} screenData The actual screen data configuration that comes from dynamicForm.screens.
 * @param {Array<string>} keyNames The field's key.
 * @param {Object} outputScreen The final dict that has the screens layout configuration.
 */
const setLayoutSchema = (layoutData, screenData, keyNames, outputScreen) => {
  const customFields = layoutData?.fields ? { fields: layoutData?.fields } : {};
  const hideNextButton = !!layoutData?.hideNextButton;
  const layoutKeyName =
    typeof keyNames[0] === 'string' ? keyNames[0] : 'layout-default';
  const customScreenName = layoutKeyName
    .toLocaleLowerCase()
    .replaceAll('_', '-');

  const screenLogicIt = {
    name: layoutData?.name || customScreenName,
    heading: layoutData?.title || screenData.question,
    subHeading: layoutData?.subHeading || '',
    route: layoutData?.name || customScreenName,
    fields: keyNames,
    nextButtonText: 'Next',
    hideNextButton,
    ...customFields,
  };

  outputScreen.push(screenLogicIt);
};

/**
 * @method setConfigurationForDataSchema
 * @description Sets the configuration for all the dataSchema.
 * @param {Object} dataSchema The dataSchema model.
 * @param {Object} screenData The actual screen data configuration that comes from dynamicForm.screens.
 * @param {Array<string>} keyNames  The field's key.
 * @param {Object} conditionalScreen Data to validate if theres is conditional screen type.
 * @param {Object} extraScreens ???
 */
const setConfigurationForDataSchema = (
  dataSchema,
  screenData,
  keyNames,
  conditionalScreen,
  extraScreens
) => {
  const isAutocompleteAddressScreen =
    screenData.flags.some((flag) => flag === 'autocomplete_address_screen') &&
    screenData.fields.length === 5;

  if (isAutocompleteAddressScreen) {
    const addressFieldName =
      screenData.fields[0].key_name.match(/.+?(?=_line1)/);
    const addressPropertyName = addressFieldName[0];

    setDataSchemaForAddressScreen(dataSchema, addressPropertyName, keyNames);
    setDataConditionalSchemaForAddressScreen(
      dataSchema,
      addressFieldName,
      conditionalScreen
    );
    keyNames.push(addressFieldName);
  } else {
    screenData.fields
      .filter((field) => field.label !== 'Middle Name')
      .forEach((field) => {
        keyNames.push(field?.key_name);

        if (field.settings?.choices?.some((choice) => choice.screen_id)) {
          extraScreens.push(field);
        }

        const data: FieldDataT = {
          type: 'string',
        };
        const ui: UiDataT = {
          label: field.label,
        };

        if (field.regex) {
          data.pattern = field.regex;
          ui.messages = {
            pattern: 'Please enter a valid value',
          };
        }

        if (field.error_message) {
          ui.messages = {
            ...ui.messages,
            required: field.error_message,
          };
        }

        switch (field.field_type) {
          case 'boolean':
            setFieldForRadioListBooleanType(field, data, ui);
            break;
          case 'text':
            if (
              field.settings.choices.length &&
              field.settings.choices.length <= 3
            ) {
              setFieldRadioListTextType(field, data, ui);
              break;
            }

            if (field.settings.choices.length > 3) {
              setFieldSelectType(field, data, ui);
              break;
            }

            if (field.widget === 'date_picker') {
              setFieldDatePickerType(ui);
              break;
            }

            if (field.key_name === 'pni_phone_number') {
              setFieldPhoneNumberType(data, ui);
              break;
            }

            data.minLength = 1;
            break;
          default:
            break;
        }

        if (conditionalScreen) {
          setDataSchemaForConditionalsScreen(
            dataSchema,
            field,
            data,
            ui,
            conditionalScreen
          );
        } else {
          setDataSchema(dataSchema, data, ui, field);
        }
      });
  }
};

const screenReading = ({
  dynamicForm,
  dynamicFormKeys,
  screenData,
  screenIndex,
  outputScreen,
  dataSchema,
  conditionalScreen,
}) => {
  const keyNames = [];
  const extraScreens = [];
  const layoutData: LayoutDataT = getActualLayoutScreen(screenData);

  setConfigurationForDataSchema(
    dataSchema,
    screenData,
    keyNames,
    conditionalScreen,
    extraScreens
  );

  const currentScreen = outputScreen.find(
    (output) => output.name === layoutData?.name
  );

  if (currentScreen && layoutData?.question.length > 1) {
    if (currentScreen?.fields.length < layoutData?.question.length) {
      currentScreen?.fields.push(...keyNames);
    }
  } else {
    setLayoutSchema(layoutData, screenData, keyNames, outputScreen);
  }

  if (extraScreens.length) {
    setDataSchemaForExtraScreens(
      extraScreens,
      dynamicForm,
      dataSchema,
      outputScreen
    );
  }

  if (!dynamicForm) {
    return outputScreen;
  }

  const nextScreenIndex =
    screenData.next_screen_id || dynamicFormKeys?.[screenIndex + 1];
  const currentScreenData = dynamicForm.screens[nextScreenIndex];

  if (!nextScreenIndex) {
    return outputScreen;
  }

  return screenReading({
    dynamicForm,
    dynamicFormKeys,
    screenData: currentScreenData,
    screenIndex: nextScreenIndex,
    outputScreen,
    dataSchema,
    conditionalScreen: null,
  });
};

/**
 * @method getDefaultStateValues
 * @description Get all the default values that come from the dynamicForm.screens API.
 * @param {Object} dataSchema The dataSchema model.
 * @param {Object}
 */
const getDefaultStateValues = (dataSchema) => {
  const actualStateValues = {};

  Object.keys(dataSchema.properties).forEach((key) => {
    if (dataSchema.properties[key].type) {
      actualStateValues[key] = dataSchema.properties[key].default || '';
    }
  });

  return actualStateValues;
};

/**
 * @method getScreens
 * @description Get all the default screens that come from the dynamicForm.screens API
 * and NOT the contemplated ones on the screen config that is defined in "./transform-to-schema-config".
 * @param {Object} layoutSchema The all layouts schema configuration.
 * @param {Object}
 */
const getScreens = (layoutSchema) => {
  Object.values(layoutSchema).reduce((acc, actual: LayoutDataT) => {
    const layoutKeyName = actual.name.replaceAll('-', ' ');
    const layoutRefKey = normalizeToCamelcase(layoutKeyName);
    const isScreenDefined = screens[layoutRefKey];

    if (!isScreenDefined) {
      const screenValue = layoutRefKey
        .split(/(?=[A-Z])/)
        .join('-')
        .toLowerCase();

      screens[layoutRefKey] = screenValue;
    }

    return acc;
  }, screens);

  return screens;
};

export const transformToSchema = (dynamicForm) => {
  const dataSchema: SuperSchema = {
    type: 'object',
    properties: {},
    required: [],
    allOf: [],
  };
  const layoutSchema = [];

  if (!dynamicForm) {
    return;
  }

  screenReading({
    dynamicForm,
    dynamicFormKeys: undefined,
    screenData: dynamicForm.screens[dynamicForm.start_screen_id],
    screenIndex: dynamicForm.start_screen_id,
    outputScreen: layoutSchema,
    dataSchema,
    conditionalScreen: null,
  });

  setCustomAllOf(dataSchema);
  setCustomDataSchema(dataSchema);
  setNewScreen(dataSchema, layoutSchema);

  // eslint-disable-next-line consistent-return
  return {
    dataSchema,
    layoutSchema: {
      screens: layoutSchema.filter((screen) => !!screen.name),
      basePath: BASE_SURE_PATH,
    },
    defaultStateValues: getDefaultStateValues(dataSchema),
    screens: getScreens(layoutSchema),
  };
};
